<script>
import axios from 'axios';

export default {
  name: 'OHCStateMixin',
  data() {
    return {
      states: {},
      statesSelect: [],
    };
  },
  mounted() {
    this.statesSelect = [];
    axios.get(this.$store.state.endpoints.offers.states)
      .then(resp => {
        this.states = resp.data;
        Object.keys(this.states).forEach(el => {
          this.statesSelect.push({ text: this.states[el], value: el });
        });
      });
  },
};
</script>
