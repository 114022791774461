<script>
import moment from 'moment';
import 'moment/locale/es';

export default {
  name: 'DateFormatMixin',
  filters: {
    date(value) {
      return moment(value).locale('es').format('DD MMMM YYYY, HH:mm');
    },
  },
};
</script>
