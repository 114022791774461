<template>
  <v-container>
    <v-layout>
      <v-card flat>
        <v-card-text>
          <v-layout wrap>
            <v-flex
              xs6
              mb-1
            >
              <OHCPositionMatch
                :ohc="ohc"
              />
            </v-flex>
            <v-flex
              xs6
              mb-1
            >
              <OHCAnswersMatch
                :ohc="ohc"
              />
            </v-flex>

            <v-flex
              xs12
              mb-1
            >
              <CandidateCard
                v-if="ohc.candidate !== undefined"
                :uuid="ohc.candidate.uuid"
              />
            </v-flex>

            <v-flex
              v-if="ohc && ohc.uuid"
              mb-1
              xs12
            >
              <OHCTests
                :ohc-uuid="ohc.uuid"
                :candidate-uuid="ohc.candidate.uuid"
                :offer-uuid="ohc.offer.uuid"
              />
            </v-flex>

            <v-flex
              mb-1
              xs12
            >
              <v-card>
                <v-card-title>
                  <h3>Respuestas</h3>
                </v-card-title>
                <v-card-text>
                  <OfferQuestionAnswers :application-uuid="uuid" />
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex>
              <v-card>
                <v-card-text>
                  <v-data-table
                    :headers="tableHeaders"
                    :items="ohcStates"
                    hide-actions
                  >
                    <template
                      slot="headers"
                    >
                      <tr>
                        <th
                          v-for="header in tableHeaders"
                          :key="header.text"
                          align="left"
                          style="font-size: small; font-weight: bold"
                        >
                          {{ header.text }}
                        </th>
                      </tr>
                    </template>
                    <template
                      slot="items"
                      slot-scope="props"
                    >
                      <td>
                        {{ formatDate(new Date(props.item.created_on)) }}
                      </td>
                      <td>
                        {{ states[props.item.status] }}
                      </td>
                      <td>
                        {{ props.item.comment }}
                      </td>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    :disabled="!ohc.is_editable"
                    color="primary"
                    block
                    @click="openOHCDialog()"
                  >
                    <v-icon left>
                      check
                    </v-icon>
                    Etiquetar
                  </v-btn>

                  <DiscardOHCButton
                    :uuid="ohc.uuid"
                    :disabled="ohc.recent_status.status == doesNotMeetProfile ||
                      !ohc.is_editable"
                    @discardSuccess="reloadOnSuccess"
                  />

                  <v-btn
                    block
                    @click="pickFile(ohc.uuid)"
                  >
                    <v-icon
                      left
                    >
                      cloud_upload
                    </v-icon>
                    Subir Informe
                  </v-btn>
                  <v-btn
                    v-show="ohc.report_file"
                    :href="ohc.report_file"
                    target="_blank"
                    color="success"
                    block
                  >
                    <v-icon left>
                      cloud_download
                    </v-icon>
                    Ver Informe
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-layout>

    <v-dialog
      v-model="updateDialog"
      max-width="350"
    >
      <OHCStatusChange
        :uuid="uuid"
        @closePressed="updateDialog = false"
        @editSuccess="reloadOnSuccess"
      />
    </v-dialog>

    <input
      ref="fileReport"
      type="file"
      style="display: none"
      accept="*"
      @change="onFilePicked"
    >
  </v-container>
</template>

<script>
import axios from 'axios';
import OfferQuestionAnswers from '@/components/candidates/OfferQuestionAnswers.vue';
import OHCTests from '@/components/offers/OHCTests.vue';
import CandidateCard from '@/components/candidates/CandidateCard.vue';
import OHCStateMixin from '@/components/mixins/OHCStateMixin.vue';
import OHCStatusChange from '@/components/forms/OHCStatusChange.vue';
import JWTConfigMixin from '../mixins/JWTConfigMixin.vue';
import OHCAnswersMatch from './OHCAnswersMatch.vue';
import OHCPositionMatch from './OHCPositionMatch.vue';
import DiscardOHCButton from './DiscardOHCButton.vue';

export default {
  name: 'OHCDetail',
  components: {
    DiscardOHCButton,
    OHCPositionMatch,
    OfferQuestionAnswers,
    CandidateCard,
    OHCStatusChange,
    OHCTests,
    OHCAnswersMatch,
  },
  mixins: [JWTConfigMixin, OHCStateMixin],
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      doesNotMeetProfile: 6,
      ohc: {},
      ohcStates: [],
      tableHeaders: [
        { text: 'Fecha de cambio' },
        { text: 'Estado' },
        { text: 'Comentario' },
      ],
      updateDialog: false,
      offerPressed: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.retrieveOhc();
    });
  },
  methods: {
    retrieveOhc() {
      const ohcEndpoint = this.$store.state.endpoints.offers.admin.retrieveUpdateOHC + this.uuid;
      const stateEndpoint = this.$store.state.endpoints.offers.admin.getOHCStates(this.uuid);
      axios.get(ohcEndpoint, this.jwtConfig)
        .then(resp => {
          this.ohc = { ...resp.data };
          axios.get(stateEndpoint, this.jwtConfig)
            .then(response => {
              this.ohcStates = response.data.results;
            });
        });
    },
    openOHCDialog() {
      this.updateDialog = true;
    },
    reloadOnSuccess() {
      this.updateDialog = false;
      this.$emit('newOhcCreated');
    },
    pickFile(ohc) {
      this.$refs.fileReport.click();
      this.offerPressed = ohc;
    },
    onFilePicked(e) {
      const file = e.target.files[0] !== undefined ? e.target.files[0] : null;
      const endpoint = this.$store.state.endpoints.offers.admin.uploadOHCReport(this.offerPressed);
      const authHeader = `${this.jwtConfig.headers.Authorization}`;
      const headers = {
        Authorization: authHeader,
        'Content-Type': 'multipart/form-data; boundary = &',
      };
      const fd = new FormData();
      fd.append('report', file);
      if (file) {
        axios.put(endpoint,
          fd, { headers })
          .then(resp => {
            this.$store.commit('adminSnackbarUp', resp.data.response);
            this.reloadOnSuccess();
          }).catch(err => {
            this.$store.commit('adminSnackbarUp', err.response.data);
          });
      }
    },
    formatDate(date) {
      const dateString = `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${
        `0${date.getDate()}`.slice(-2)}`;
      const timeString = `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(-2)}:${
        `${date.getSeconds()}`.slice(-2)}`;
      return `${dateString} ${timeString}`;
    },
  },
};
</script>
