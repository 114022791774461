<template>
  <v-container>
    <v-layout
      row
      wrap
    >
      <v-flex xs3>
        <v-btn
          color="warning"
          @click="$router.push({name: 'admin:offers'})"
        >
          <v-icon left>
            undo
          </v-icon>
          Volver
        </v-btn>
      </v-flex>
      <v-flex xs3>
        <v-spacer />
      </v-flex>
      <v-flex xs3>
        <v-btn
          color="primary"
          @click="addohc_dialog = true"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Agregar Candidato
        </v-btn>
      </v-flex>
      <v-flex xs3>
        <v-btn
          color="primary"
          @click="longlist_dialog = true"
        >
          <v-icon left>
            mdi-clipboard-text-outline
          </v-icon>
          Generar Longlist
        </v-btn>
      </v-flex>
    </v-layout>

    <OfferApplicantsTable :offer-uuid="$route.params.uuid" />

    <v-dialog
      v-model="longlist_dialog"
      max-width="300"
      persistent
    >
      <v-card v-if="longlist_dialog">
        <v-card-title>
          <h3 class="headline">
            Generar Longlist
          </h3>
        </v-card-title>
        <v-card-text>
          <v-layout wrap>
            <v-flex xs12>
              <p class="text--primary text-xs-center">
                La generación de Longlist es una característica experimental y limitada, por lo que
                se deberán realizar modificaciones manuales al archivo generado.
              </p>
            </v-flex>
            <v-flex xs12>
              <v-select
                v-model="llFormat"
                :items="formats"
                label="Formato de Longlist"
                single-line
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            @click="generateLl"
          >
            Generar
          </v-btn>
          <v-spacer />
          <v-btn
            color="warning"
            @click="longlist_dialog = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addohc_dialog"
      max-width="600"
      persistent
    >
      <v-card v-if="addohc_dialog">
        <v-card-title>
          <h3 class="headline">
            Agregar Candidato
          </h3>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-lg>
            <v-layout col>
              <v-flex
                xs12
                sm6
              >
                <v-autocomplete
                  v-model="candidateAdd"
                  label="Escriba nombre o apellido"
                  :items="candidateList"
                  :search-input.sync="search"
                  v-bind="$attrs"
                  no-data-text="Escribe para buscar..."
                />
                <v-select
                  v-model="stateAdd"
                  :items="filteredStatesSelect"
                  label="Estado"
                  single-line
                />
                <v-textarea
                  v-model="commentAdd"
                  outline
                  no-resize
                  label="Comentario"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                Pretensión de renta
                <v-layout row>
                  <v-flex xs5>
                    <v-text-field
                      v-model="wageMin"
                      label="Desde"
                    />
                  </v-flex>

                  <v-flex xs5>
                    <v-spacer />
                    <v-text-field
                      v-model="wageMax"
                      label="Hasta"
                    />
                  </v-flex>
                </v-layout>
                <div
                  v-for="q in questions"
                  :key="q.uuid"
                >
                  {{ q.question }}
                  <v-textarea
                    v-model="answers[q.uuid]"
                    label="Respuesta"
                    outline
                    no-resize
                  />
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            @click="addOhc"
          >
            Agregar
          </v-btn>
          <v-spacer />
          <v-btn
            color="warning"
            @click="addohc_dialog = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import OHCStateMixin from '@/components/mixins/OHCStateMixin.vue';
import OfferApplicantsTable from '@/components/offers/OfferApplicantsTable.vue';

let cancel;
const { CancelToken } = axios;

export default {
  name: 'Offers',
  components: {
    OfferApplicantsTable,
  },
  mixins: [JWTConfigMixin, OHCStateMixin],
  data() {
    return {
      formats: [
        { text: 'TyA', value: 'tya' },
        { text: 'Insigni', value: 'insigni' },
        { text: 'Executive', value: 'executive' },
      ],
      llFormat: '',
      longlist_dialog: false,
      addohc_dialog: false,
      candidateAdd: '',
      stateAdd: '',
      commentAdd: '',
      candidateList: [],
      search: '',
      wageMin: 0,
      wageMax: 0,
      questions: [],
      answers: {},
    };
  },
  computed: {
    /** Only show states that admins should be able to set. */
    filteredStatesSelect() {
      const validStates = [
        'Contratado',
        'En proceso',
        'Recomendado',
        'Sin calificar',
        'No recomendado',
      ];
      return this.statesSelect.filter(data => validStates.indexOf(data.text) >= 0);
    },
  },
  watch: {
    search(newValue) {
      if (newValue && newValue.length > 3) {
        if (cancel !== undefined) {
          cancel();
          console.log('cancelled');
        }
        this.candidateList = [];
        const ep = this.$store.state.endpoints.offers.admin.lookupCandidates(newValue);
        axios.get(ep, {
          cancelToken: new CancelToken((c => {
            cancel = c;
          })),
          ...this.jwtConfig,
        }).then(resp => {
          this.candidateList = resp.data.map(el => ({
            text: `${el.first_name} ${el.last_name}`,
            value: el.uuid,
          }));
        }).catch(error => {
          console.error(error);
        });
      }
    },
  },
  methods: {
    generateLl() {
      const payload = {
        uuid: this.$route.params.uuid,
        formato: this.llFormat,
      };
      const ep = this.$store.state.endpoints.offers.admin.getLLUrl;
      axios.post(ep, payload, this.jwtConfig)
        .then(response => {
          window.open(response.data.url, '_top');
        });
    },
    addOhc() {
      const payload = {
        offer: this.$route.params.uuid,
        candidate: this.candidateAdd,
        status: this.stateAdd,
        comment: this.commentAdd,
        expected_wage_min: this.wageMin,
        expected_wage_max: this.wageMax,
        comment_date: new Date(),
        answers: this.answers,
      };

      axios.post(this.$store.state.endpoints.offers.admin.createOHC, payload, this.jwtConfig)
        .then(() => {
          this.addohc_dialog = false;
          this.$store.commit('adminSnackbarUp', 'Candidato ingresado con éxito.');
          this.$router.go();
        });
    },
  },
};
</script>
