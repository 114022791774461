<template>
  <v-form>
    <v-card>
      <v-card-text>
        <v-container fluid>
          <v-flex xs12>
            <div class="headline">
              Cambiar estado del candidato
            </div>
          </v-flex>
          <v-flex xs12>
            <v-select
              v-model="state"
              :items="filteredStatesSelect"
              outlined
              label="Estado"
            />
          </v-flex>
          <v-flex xs12>
            <v-textarea
              v-model="comment"
              outline
              no-resize
              label="Comentario"
            />
          </v-flex>
          <v-flex
            v-if="states[state] === 'No cumple perfil'"
            xs12
          >
            Se enviará un correo al candidato notificando que no quedó seleccionado.
          </v-flex>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :disabled="updating"
          @click="sendUpdate"
        >
          Guardar
        </v-btn>
        <v-spacer />
        <v-btn @click="closeDialog">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import OHCStateMixin from '@/components/mixins/OHCStateMixin.vue';

export default {
  name: 'OHCStatusChange',
  mixins: [JWTConfigMixin, OHCStateMixin],
  props: {
    uuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      state: 0,
      comment: '',
      updating: false,
    };
  },
  computed: {
    /** Only show states that admins should be able to set. */
    filteredStatesSelect() {
      const validStates = [
        'Contratado',
        'En proceso',
        'Recomendado',
        'Sin calificar',
        'Sin revisar',
        'No recomendado',
      ];
      return this.statesSelect.filter(data => validStates.indexOf(data.text) >= 0);
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closePressed');
    },
    sendUpdate() {
      const payload = {
        ohc_id: this.uuid,
        status: this.state,
        comment: this.comment,
        adminhr: this.$store.state.adminName,
      };
      this.updating = true;
      axios.put(this.$store.state.endpoints.offers.admin.retrieveUpdateOHC + this.uuid,
        payload, this.jwtConfig)
        .then(resp => {
          this.updating = false;
          this.$store.commit('adminSnackbarUp', resp.data.response);
          this.$emit('editSuccess');
        });
    },
  },
};
</script>
