<template>
  <v-layout v-if="!candidate">
    <v-flex xs12>
      <p>Cargando...</p>
    </v-flex>
  </v-layout>

  <v-layout
    v-else
    row
    wrap
  >
    <v-flex xs12>
      <v-card>
        <v-card-text>
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
              md4
              text-xs-center
            >
              <v-avatar size="88">
                <v-img
                  :src="`${profilePic}`"
                  class="mb-4"
                />
              </v-avatar>

              <h3 class="headline mb-2">
                {{ candidate.first_name }} {{ candidate.last_name }}
              </h3>

              <div class="mb-2">
                <a :href="`mailto:${candidate.email}`">{{ candidate.email }}</a>
              </div>

              <div class="subheading font-weight-bold">
                <a
                  v-if="$vuetify.breakpoint.smAndDown"
                  :href="`tel:${candidate.phone_number}`"
                >{{ candidate.phone_number }}</a>
                <template v-else>
                  {{ candidate.phone_number }}
                </template>
              </div>
            </v-flex>

            <v-flex
              xs12
              md8
            >
              <table style="text-align: left">
                <thead />
                <tbody>
                  <tr>
                    <th>Fecha de nacimiento:</th>
                    <td style="font-size: medium">
                      {{ candidate.birth_date }}
                    </td>
                  </tr>

                  <tr>
                    <th>País de Residencia:</th>
                    <td style="font-size: medium">
                      {{ candidate.city_region.country }}
                    </td>
                  </tr>

                  <tr>
                    <th>Teléfono:</th>
                    <td style="font-size: medium">
                      {{ candidate.phone_number }}
                    </td>
                  </tr>

                  <tr>
                    <th>Dirección:</th>
                    <td style="font-size: medium">
                      {{ candidate.address }}
                    </td>
                  </tr>

                  <tr>
                    <th>Rut:</th>
                    <td style="font-size: medium">
                      {{ candidate.national_id }}
                    </td>
                  </tr>

                  <tr>
                    <th>Título/Grado:</th>
                    <td style="font-size: medium">
                      {{ candidate.degree_name }}
                    </td>
                  </tr>

                  <tr>
                    <th>CV:</th>
                    <td style="font-size: medium">
                      <a
                        :href="candidate.cv"
                        target="_blank"
                      >Descargar</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '../mixins/JWTConfigMixin.vue';

export default {
  name: 'CandidateCard',
  mixins: [JWTConfigMixin],
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      candidate: null,
    };
  },
  computed: {
    profilePic() {
      return (this.candidate.profile_pic) ? this.candidate.profile_pic : 'https://avataaars.io/';
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadCandidate();
    });
  },
  methods: {
    loadCandidate() {
      axios.get(this.$store.state.endpoints.candidates.get(this.uuid), this.jwtConfig)
        .then(response => {
          this.candidate = response.data;
        });
    },
  },
};
</script>
<style scoped>
  div .mb-2 {
    font-size: medium;
  }
</style>
