<template>
  <v-card>
    <v-card-title>
      <h3>Cumplimiento de Requisitos</h3>
    </v-card-title>
    <v-card-text>
      <v-progress-circular
        :size="100"
        :width="15"
        :value="ohc.answers_match ? parseFloat(ohc.answers_match * 100).toFixed(2) : 0"
        :color="color"
      >
        {{ ohc.answers_match ? `${parseFloat(ohc.answers_match * 100).toFixed(2)}%` : '-' }}
      </v-progress-circular>
    </v-card-text>
  </v-card>
</template>

<script>
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'OHCAnswersMatch',
  mixins: [
    JWTConfigMixin,
  ],
  props: {
    ohc: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color() {
      return 'teal';
    },
    endpoint() {
      return this.$store.state.endpoints.offers.admin.retrieveUpdateOHCMatches(this.ohc.uuid);
    },
    payload() {
      return {
        uiud: this.ohc.uuid,
        answers_match: this.ohc.answers_match,
      };
    },
  },
};
</script>
