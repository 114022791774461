<template>
  <v-card>
    <v-card-title>
      <h3>Tests Relevantes</h3>
    </v-card-title>
    <v-card-text>
      <v-layout
        row
        wrap
      >
        <v-flex
          v-if="has_no_disc || has_no_cognitive"
          xs12
        >
          <v-btn
            v-if="has_no_disc"
            color="success"
            :disabled="loading_disc_button"
            @click="sendDISC"
          >
            Enviar DISC
          </v-btn>
          <v-btn
            v-if="has_no_cognitive"
            color="success"
            :disabled="loading_cognitive_button"
            @click="sendCognitive"
          >
            Enviar Cognitivo
          </v-btn>
        </v-flex>
        <v-flex xs12>
          <v-data-table
            :headers="headers"
            :items="tests"
            :loading="loading_table"
            no-data-text="No se encontraron tests."
            hide-actions
            disable-pagination
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <td>{{ props.item.timestamp | date }}</td>
              <td>{{ props.item.type }}</td>
              <td>{{ props.item.link_answer }}</td>
              <td>
                <v-tooltip
                  v-if="props.item.blob_error"
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      flat
                      icon
                      v-on="on"
                    >
                      <v-icon>mdi-alert</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ props.item.blob_error_message }}</span>
                </v-tooltip>
                <v-btn
                  v-else-if="props.item.blob_link"
                  flat
                  icon
                  color="blue lighten-2"
                  :href="props.item.blob_link"
                  :download="`${props.item.type}.pdf`"
                  target="_blank"
                >
                  <v-icon>mdi-file-pdf-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  flat
                  icon
                >
                  <v-icon>mdi-reload mdi-spin</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '../mixins/JWTConfigMixin.vue';
import DateFormatMixin from '../mixins/DateFormatMixin.vue';

export default {
  name: 'OHCTests',
  mixins: [
    JWTConfigMixin,
    DateFormatMixin,
  ],
  props: {
    ohcUuid: {
      type: String,
      required: true,
    },
    candidateUuid: {
      type: String,
      required: true,
    },
    offerUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading_table: false,
      loading_disc_button: false,
      loading_cognitive_button: false,
      tests: [],
      headers: [
        {
          text: 'Fecha',
          value: 'timestamp',
        },
        {
          text: 'Tipo de Test',
          value: 'type',
        },
        {
          text: 'Link para contestar',
          sortable: false,
        },
        {
          text: 'Informe',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    has_no_disc() {
      return !this.loading_table && !this.tests.find(d => d.type === 'DISC');
    },
    has_no_cognitive() {
      return !this.loading_table && !this.tests.find(d => d.type === 'Cognitivo');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getTests();
    });
  },
  methods: {
    getTests() {
      this.loading_table = true;
      const endpoint = this.$store.state.endpoints.offers.admin.getOHCTests(this.ohcUuid);
      axios.get(endpoint, this.jwtConfig)
        .then(response => {
          this.loading_table = false;
          this.tests = response.data.map(data => {
            const copy = {
              ...data,
              blob_link: null,
              blob_error: false,
              blob_error_message: '',
            };
            axios.get(data.link_pdf, {
              responseType: 'blob',
            }).then(resp => {
              // download file using axios: https://gist.github.com/javilobo8/0+97c30a233786be52070986d8cdb1743
              copy.blob_link = window.URL.createObjectURL(new Blob([resp.data]));
            }).catch(error => {
              /**
               if (error.response.data.type === 'application/json') {
                    const reader = new FileReader();
                    const blob = new Blob([error.response.data]);
                    reader.addEventListener('loadend', (e) => {
                        copy.blob_error = JSON.parse(e.srcElement.result);
                    });
                    reader.readAsText(blob);
                }
               console.error(error);
               */
              copy.blob_error = true;

              const reader = new FileReader();
              const blob = new Blob([error.response.data]);
              reader.addEventListener('loadend', e => {
                const dataBlob = JSON.parse(e.srcElement.result);
                copy.blob_error_message = dataBlob.msj;
              });
              reader.readAsText(blob);
            });
            return copy;
          });
        }).catch(() => {
          this.loading_table = false;
        });
    },
    sendDISC() {
      this.loading_disc_button = true;
      const endpoint = this.$store.state.endpoints.candidates.sendDISC(this.candidateUuid);
      const payload = {
        offer_uuid: this.offerUuid,
      };

      axios.post(endpoint, payload, this.jwtConfig)
        .then(response => {
          this.loading_disc_button = false;
          console.log(response);
          this.getTests();
        }).catch(error => {
          this.loading_disc_button = false;
          console.error(error);
        });
    },
    sendCognitive() {
      this.loading_cognitive_button = true;
      const endpoint = this.$store.state.endpoints.candidates.sendCognitive(this.candidateUuid);
      const payload = {
        offer_uuid: this.offerUuid,
      };

      axios.post(endpoint, payload, this.jwtConfig)
        .then(response => {
          this.loading_cognitive_button = false;
          console.log(response);
          this.getTests();
        }).catch(error => {
          this.loading_cognitive_button = false;
          if (error.response.data.msg) {
            this.$store.commit('adminSnackbarUp', error.response.data.msg);
          }
        });
    },

  },
};
</script>
