<template>
  <v-btn
    :disabled="disabled || !uuid"
    color="red"
    dark
    block
    @click="discard_ohc_dialog=true"
  >
    <v-icon left>
      cancel
    </v-icon>
    Descartar
    <v-dialog
      v-model="discard_ohc_dialog"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-text>
          <v-container fluid>
            <v-flex xs12>
              <v-icon
                large
                color="primary"
              >
                warning
              </v-icon>
              <div class="headline">
                Al descartar un candidato:
              </div>
              <br>
              <ul>
                <li>Se enviará automáticamente un email de agradecimiento</li>
                <li>El postulante será descartado del proceso</li>
              </ul>
            </v-flex>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red"
            :dark="!discarding"
            :disabled="discarding"
            @click="discard"
          >
            <v-icon
              left
              v-text="discarding ? 'mdi-email mdi-spin' : 'cancel'"
            />
            <template v-if="discarding">
              Descartando
            </template>
            <template v-else>
              Descartar
            </template>
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="discarding"
            @click="discard_ohc_dialog = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'DiscardOHCButton',
  mixins: [
    JWTConfigMixin,
  ],
  props: {
    uuid: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      discard_ohc_dialog: false,
      discarding: false,
    };
  },
  methods: {
    discard() {
      const payload = {
        status: 6, // TODO fix this: hardcoded status code
        comment: '',
      };
      this.discarding = true;
      axios.put(this.$store.state.endpoints.offers.admin.retrieveUpdateOHC + this.uuid,
        payload, this.jwtConfig)
        .then(resp => {
          this.discarding = false;
          this.discard_ohc_dialog = false;
          this.$store.commit('adminSnackbarUp', resp.data.response);
          this.$emit('discardSuccess');
        });
    },
  },
};
</script>
