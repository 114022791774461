<template>
  <v-layout
    v-if="application"
    row
    wrap
  >
    <v-flex xs12>
      <p><strong>Pretensión de renta</strong></p>
      <template
        v-if="application.expected_wage || application.expected_wage_min < 0 ||
          application.expected_wage_max < 0"
      >
        <p>{{ application.expected_wage }}</p>
      </template>
      <template v-else>
        <p>Entre {{ application.expected_wage_min }} y {{ application.expected_wage_max }}</p>
      </template>
    </v-flex>
    <v-flex
      v-for="answer in application.answers"
      :key="answer.question.uuid"
      xs6
    >
      <p><strong>{{ answer.question.question }}</strong></p>
      <p>{{ answer.answer }}</p>
    </v-flex>
    <v-flex
      v-for="(ra, index) in application.range_answers"
      :key="`range_answer_${index}`"
      xs6
    >
      <p><strong>{{ ra.option.question.text }}</strong></p>
      <p v-if="ra.option.question.question_type === type_likert">
        {{ ra.option.value }}
        <template>
          de {{ ra.option.question.max_note }}
        </template>
      </p>
      <p
        v-if="ra.option.question.question_type === type_dichotomy ||
          ra.option.question.question_type === type_bad"
      >
        {{ ra.option.text }}
      </p>
    </v-flex>
    <v-flex
      v-for="(mq, index) in multiple_questions"
      :key="`multiple_questions_answers_${index}`"
      xs12
    >
      <p><strong>{{ mq.text }}</strong></p>
      <v-layout>
        <div
          v-for="(op, idx) in mq.multi_options"
          :key="`multi_options_${idx}`"
        >
          <p
            v-if="pkAnswers.includes(op.pk)"
          >
            <v-chip>{{ op.text }}</v-chip>
          </p>
        </div>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '../mixins/JWTConfigMixin.vue';

export default {
  name: 'OfferQuestionAnswers',
  mixins: [JWTConfigMixin],
  props: {
    applicationUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      application: null,
      type_likert: 2,
      type_dichotomy: 1,
      type_bad: 0,
      multiple_questions: [],
    };
  },
  computed: {
    pkAnswers() {
      return this.application.multiple_questions_answers.map(data => data.pk);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadApplication();
      this.loadMultiChoiceQuestions();
    });
  },
  methods: {
    loadApplication() {
      const endpoint = this.$store.state.endpoints.offers.admin.application(this.applicationUuid);
      axios.get(endpoint, this.jwtConfig)
        .then(response => {
          this.application = response.data;
        });
    },
    loadMultiChoiceQuestions() {
      const endpoint = this.$store.state.endpoints.offers.admin.offerMultiChoiceQuestions(
        this.$route.params.uuid,
      );
      axios.get(endpoint, this.jwtConfig)
        .then(response => {
          this.multiple_questions = response.data;
        });
    },
  },
};
</script>
