<template>
  <v-card>
    <v-card-title>
      <h3>Adecuación según Tests</h3>
    </v-card-title>
    <v-card-text>
      <v-progress-circular
        :size="100"
        :width="15"
        class="myPointer"
        :value="roundPositionMatch || ohc.test_match ?
          roundPositionMatch || ohc.test_match : 0"
        :color="color"
        @click="dialog=true"
      >
        <div v-if="isCalculating">
          <v-tooltip top>
            <v-icon slot="activator">
              mdi-reload mdi-spin
            </v-icon>
            <span>Calculando...</span>
          </v-tooltip>
        </div>
        <div v-else>
          {{ roundPositionMatch || ohc.test_match ?
            roundPositionMatch || ohc.test_match : '-' }}
        </div>
      </v-progress-circular>
      <v-btn
        v-if="ohc.position_match && ohc.disc_match"
        class="ml-5"
        disabled
        color="info"
      >
        Calcular
      </v-btn>
      <v-btn
        v-else
        class="ml-5"
        :disabled="disabled_button"
        color="info"
        @click="calculate"
      >
        Calcular
        <v-tooltip
          v-if="isWarning"
          top
          class="ml-2"
        >
          <v-icon slot="activator">
            mdi-alert
          </v-icon>
          <span>{{ message }}</span>
        </v-tooltip>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'OHCPositionMatch',
  mixins: [
    JWTConfigMixin,
  ],
  props: {
    ohc: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: '',
      check: false,
      isCalculating: false,
      isWarning: false,
      calculate_postion: '',
      message: '',
      disabled_button: null,
    };
  },
  computed: {
    color() {
      return 'teal';
    },
    endpoint() {
      return this.$store.state.endpoints.offers.admin.calculateTestMatch(this.ohc.uuid);
    },
    payload() {
      return {
        uiud: this.ohc.uuid,
        test_match: this.ohc.test_match,
      };
    },
    roundPositionMatch() {
      return Math.floor(this.calculate_postion);
    },
  },
  methods: {
    calculate() {
      this.isCalculating = true;
      this.dialog = false;
      axios.put(this.endpoint, {}, this.jwtConfig)
        .then(response => {
          console.log(response.data);
          this.isCalculating = false;
          this.isWarning = true;
          this.calculate_postion = response.data.test_match;

          if (response.data.message.disc === null && response.data.message.cognitive !== null) {
            this.message = 'Solo está respondido el test DISC';
          } else if (response.data.message.disc !== null
          && response.data.message.cognitive === null) {
            this.message = 'Solo está respondido el test COGNITIVO';
          } else {
            this.message = 'Faltan los 2 test por responder';
          }

          if (response.data.message.disc === null && response.data.message.cognitive === null) {
            this.isWarning = false;
            this.disabled_button = true;
          }
        })
        .catch(error => {
          console.error(error);
          this.isCalculating = false;
        });
    },
  },
};
</script>

<style scoped>
  .myPointer:hover {
  cursor: pointer;
}
</style>
