<template>
  <v-card flat>
    <v-card-text>
      <v-layout
        row
        wrap
      >
        <v-flex
          v-if="offer"
          xs12
        >
          <p class="headline">
            {{ offer.title }}
          </p>
          <p class="subtitle">
            {{ offer.multiposting_error }}
          </p>
          <p class="subtitle">
            {{ offer.admin }}
          </p>
        </v-flex>
        <v-flex
          xs12
          md4
        >
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Filtrar Tabla"
          />
        </v-flex>
        <v-flex md5 />
        <v-flex
          xs12
          md3
        >
          <v-btn
            block
            @click="advanced_filters = true"
          >
            Filtros Avanzados
            <v-icon right>
              mdi-filter
            </v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs12>
          <v-chip
            v-for="key in Object.keys(active_filters)"
            :key="key"
            info
          >
            {{ translatedFilter(key) }}: {{ active_filters[key] }}
          </v-chip>
        </v-flex>
        <v-flex xs12>
          <v-tabs
            v-model="active_tab"
          >
            <v-tab
              v-for="(state, index) in tab_values"
              :key="`tab-${index + 1}`"
              ripple
            >
              {{ state }}
            </v-tab>
          </v-tabs>
        </v-flex>
        <v-flex xs12>
          <v-data-table
            ref="applicantsTable"
            :headers="headers"
            :items="selected_applicants"
            :search="search"
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <td
                :style="backgroundColor(props.item)"
              >
                {{ props.item.candidate.first_name }}
              </td>
              <td
                :style="backgroundColor(props.item)"
              >
                {{ props.item.candidate.last_name }}
              </td>
              <td
                :style="backgroundColor(props.item)"
              >
                <template v-if="backgroundColor(props.item).backgroundColor === '#eeeeee'">
                  Revisado
                </template>
                <template v-else>
                  {{ props.item.recent_status.status_name }}
                </template>
              </td>
              <td
                :style="backgroundColor(props.item)"
                style="cursor:pointer"
              >
                <template v-if="props.item.answers_match !== null">
                  <v-tooltip
                    right
                    color="white"
                    min-width="200"
                    max-width="600"
                  >
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        {{ parseFloat(props.item.answers_match * 100).toFixed(0) }}%
                      </span>
                    </template>
                    <v-card
                      elevation="0"
                    >
                      <v-card-title class="mx-2 my-2">
                        <h2>Respuestas</h2>
                      </v-card-title>
                      <v-card-text class="mx-2 my-2">
                        <OfferQuestionAnswers :application-uuid="props.item.uuid" />
                      </v-card-text>
                    </v-card>
                  </v-tooltip>
                </template>
                <template v-else>
                  -
                </template>
              </td>
              <td
                :style="backgroundColor(props.item)"
              >
                <template v-if="props.item.wage_match !== null">
                  {{ props.item.wage_match ? "Dentro del rango" : "Fuera del rango" }}
                </template>
                <template v-else>
                  -
                </template>
              </td>
              <td
                :style="backgroundColor(props.item)"
              >
                <template v-if="props.item.distance !== null">
                  {{ parseFloat(props.item.distance).toFixed(0) }} km
                </template>
                <template v-else>
                  -
                </template>
              </td>
              <td :style="backgroundColor(props.item)">
                <v-btn @click="seeOHC(props.item)">
                  <v-icon left>
                    insert_comment
                  </v-icon>
                  Ver
                </v-btn>
              </td>
              <td
                :style="backgroundColor(props.item)"
              >
                <DiscardOHCButton
                  v-if="props.item.is_editable"
                  :uuid="props.item.uuid"
                  :disabled="props.item.recent_status.status == doesNotMeetProfile ||
                    !props.item.is_editable"
                  @discardSuccess="reloadOnSuccess"
                />
                <v-tooltip
                  v-else
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      @change="on"
                    >
                      <v-icon>warning</v-icon>
                    </v-btn>
                  </template>
                  <span class="body-1">
                    El candidato esta "en proceso" en otra oferta, por lo tanto no puede cambiar
                    su estado en este proceso
                  </span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-flex>
        <v-dialog
          v-if="detail_application"
          v-model="detail_application"
          max-width="800"
          persistent
        >
          <v-card
            flat
          >
            <v-card-title
              class="headline grey lighten-2"
              primary-title
            >
              {{ detail_application.candidate.first_name }}
              {{ detail_application.candidate.last_name }}
              <v-spacer />
              <v-btn
                :to="{
                  name: 'admin:candidate:applications',
                  params: { uuid: detail_application.candidate.uuid }
                }"
              >
                <v-icon>mdi-account</v-icon>
                Ver Perfil
              </v-btn>
              <v-btn
                v-if="hasPreviousApplication(detail_application.uuid)"
                @click="previousDetail(detail_application.uuid)"
              >
                <v-icon left>
                  mdi-arrow-left
                </v-icon>
                Anterior
              </v-btn>
              <v-btn
                v-if="hasNextApplication(detail_application.uuid)"
                @click="nextDetail(detail_application.uuid)"
              >
                Siguiente
                <v-icon right>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <OHCDetail
                :key="detail_application.uuid"
                :uuid="detail_application.uuid"
                @newOhcCreated="reloadOnSuccess"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                flat
                @click="detail_application = false"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-navigation-drawer
          v-model="advanced_filters"
          right
          temporary
          fixed
        >
          <v-form @submit.prevent="loadApplicants">
            <v-card
              v-if="advanced_filters"
              flat
            >
              <v-card-title class="headline">
                Filtros Avanzados
              </v-card-title>
              <v-card-text class="justify-center">
                <v-layout wrap>
                  <v-flex xs6>
                    <v-text-field
                      v-model="filters.min_salary"
                      label="Renta desde"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      v-model="filters.max_salary"
                      label="Renta hasta"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="filters.ccompany"
                      label="Empresa"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="filters.cposition"
                      label="Cargo"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="filters.cstudies"
                      label="Estudios"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-combobox
                      v-model="filters.ctags"
                      multiple
                      prepend-icon="search"
                      label="Tags"
                      append-icon
                      chips
                      deletable-chips
                      hint="Presiona Enter después de agregar cada tag"
                      persistent-hint
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-btn
                      color="primary"
                      type="submit"
                      block
                    >
                      Filtrar
                    </v-btn>
                    <v-btn
                      block
                      @click="clearAndFilter"
                    >
                      Limpiar
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-form>
        </v-navigation-drawer>
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import OHCStateMixin from '@/components/mixins/OHCStateMixin.vue';
import DiscardOHCButton from '@/components/offers/DiscardOHCButton.vue';
import OHCDetail from '@/components/offers/OHCDetail.vue';
import OfferQuestionAnswers from '@/components/candidates/OfferQuestionAnswers.vue';

export default {
  name: 'OfferApplicantsTable',
  components: { DiscardOHCButton, OHCDetail, OfferQuestionAnswers },
  mixins: [JWTConfigMixin, OHCStateMixin],
  props: {
    offerUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      doesNotMeetProfile: 6,
      active_tab: 0,
      search: '',
      tab_values: [
        'Todos',
        'Sin revisar',
        'En proceso',
        'Recomendado',
        'No recomendado',
        'No cumple perfil',
        'Contratado',
      ],
      updateDialog: false,
      detail_application: null,
      offerPressed: '',
      applicants: [],
      filters: {
        min_salary: '',
        max_salary: '',
        ccompany: '',
        cposition: '',
        ctags: '',
        cstudies: '',
      },
      advanced_filters: false,
      ohcChangeData: {
        status: 0,
        uuid: '',
      },
      headers: [
        {
          text: 'Nombre',
          value: 'candidate.first_name',
        },
        {
          text: 'Apellido',
          value: 'candidate.last_name',
        },
        {
          text: 'Estado Actual',
          value: 'computed_state',
        },
        {
          text: 'Puntuación de respuestas',
          value: 'answers_match',
        },
        {
          text: 'Adecuación a renta',
          value: 'wage_match',
        },
        {
          text: 'Distancia',
          value: 'distance',
        },
        {
          text: 'Detalle Postulación',
          sortable: false,
        },
        {
          text: 'Acciones',
          sortable: false,
        },
      ],
      marketplace: 2,
    };
  },
  computed: {
    applicants_endpoint() {
      return this.$store.state.endpoints.offers.admin.applicantsV2(this.offerUuid);
    },
    active_filters() {
      const copy = { ...this.filters };
      Object.keys(copy).forEach(key => {
        if (!copy[key]) {
          delete copy[key];
        }
      });
      return copy;
    },
    offer() {
      if (this.applicants.length > 0) {
        return this.applicants[0].offer;
      }
      return null;
    },
    selected_state() {
      return this.tab_values[this.active_tab];
    },
    computed_applicants() {
      return this.applicants.map(data => {
        let computedState = data.recent_status.status_name;
        if (this.backgroundColor(data).backgroundColor === '#eeeeee') {
          // this means that the state is "sin calificar" BUT it's been seen
          computedState = 'Revisado';
        }
        return {
          ...data,
          computed_state: computedState,
        };
      });
    },
    selected_applicants() {
      if (this.active_tab > 0) {
        return this.computed_applicants.filter(
          ohc => ohc.computed_state === this.selected_state,
        );
      }
      return [...this.computed_applicants];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadApplicants();
    });
  },
  methods: {
    /**
     * Whether the given UUID has a 'next' element, ie, if it's not the last element of the array
     * */
    hasNextApplication(uuid) {
      // this is what's actually being shown on the table, with filters and all
      const { filteredItems } = this.$refs.applicantsTable;

      const index = filteredItems.findIndex(data => data.uuid === uuid);
      return index < filteredItems.length - 1;
    },
    /**
     * Whether the given UUID has a 'previous' element, ie, if it's not the first element of
     * the array
     * */
    hasPreviousApplication(uuid) {
      // this is what's actually being shown on the table, with filters and all
      const { filteredItems } = this.$refs.applicantsTable;

      const index = filteredItems.findIndex(data => data.uuid === uuid);
      return index > 0;
    },
    /**
     * Sets detail_application to be the next element in the array, after the given UUID.
     * This way, the dialog data changes accordingly.
     * */
    nextDetail(uuid) {
      if (this.hasNextApplication(uuid)) {
        // this is what's actually being shown on the table, with filters and all
        const { filteredItems } = this.$refs.applicantsTable;

        const index = filteredItems.findIndex(data => data.uuid === uuid);
        this.detail_application = filteredItems[index + 1];
        this.markAsSeen(this.detail_application);
      }
    },
    /**
     * Sets detail_application to be the previous element in the array, after the given UUID.
     * This way, the dialog data changes accordingly.
     * */
    previousDetail(uuid) {
      if (this.hasPreviousApplication(uuid)) {
        // this is what's actually being shown on the table, with filters and all
        const { filteredItems } = this.$refs.applicantsTable;

        const index = filteredItems.findIndex(data => data.uuid === uuid);
        this.detail_application = filteredItems[index - 1];
        this.markAsSeen(this.detail_application);
      }
    },
    translatedFilter(key) {
      return {
        min_salary: 'pretensión renta mínima',
        max_salary: 'pretensión renta máxima',
        ccompany: 'empresa',
        cposition: 'cargo',
        ctags: 'tag',
        cstudies: 'estudios',
      }[key];
    },
    clearAndFilter() {
      this.filters = {
        min_salary: '',
        max_salary: '',
        ccompany: '',
        cposition: '',
        ctags: '',
        cstudies: '',
      };
      this.loadApplicants();
    },
    loadApplicants() {
      this.$store.commit('setLoading');
      this.advanced_filters = false;
      const offep = this.$store.state.endpoints.offers.admin.offerQuestions(this.offerUuid);
      axios.get(offep, this.jwtConfig)
        .then(resp => {
          this.questions = resp.data;
        });
      axios.get(this.applicants_endpoint, {
        ...this.jwtConfig,
        params: {
          ...this.active_filters,
        },
      }).then(response => {
        this.applicants = response.data;
        this.$store.commit('unsetLoading');
      });
    },
    seeOHC(ohc, isDownload = false) {
      if (ohc.offer.offer_owner !== this.marketplace) {
        const payload = {
          seen: true,
        };
        const endpoint = this.$store.state.endpoints.offers.admin.retrieveUpdateOHC + ohc.uuid;
        axios.patch(endpoint, payload, this.jwtConfig).then(() => {
          this.applicants.filter(appl => (appl.uuid === ohc.uuid))[0].seen = true;
          if (isDownload) {
            window.open(ohc.candidate.cv, '_blank');
          } else {
            this.detail_application = ohc;
          }
        });
      } else {
        this.detail_application = ohc;
      }
    },
    markAsSeen(ohc) {
      if (!ohc.seen) {
        const payload = {
          seen: true,
        };
        const endpoint = this.$store.state.endpoints.offers.admin.retrieveUpdateOHC + ohc.uuid;
        axios.patch(endpoint, payload, this.jwtConfig).then(() => {
          this.applicants.filter(appl => (appl.uuid === ohc.uuid))[0].seen = true;
        });
      }
    },
    reloadOnSuccess() {
      this.detail_application = null;
      this.loadApplicants();
    },
    backgroundColor(ohc) {
      let ohcColor = '#fff';
      const textColor = '#000';
      if (ohc.seen) {
        ohcColor = '#eeeeee';
      }
      if (ohc.recent_status.status === 5) {
        ohcColor = '#d0eaff';
      } else if (ohc.recent_status.status < 5 && ohc.recent_status.status >= 2) { // 2,3,4
        ohcColor = '#fff5ad';
      } else if (ohc.recent_status.status === -1) {
        // rejected
        ohcColor = '#edc2b7';
      } else if (ohc.recent_status.status === 6) {
        // unfit
        ohcColor = '#a7a7a7';
      } else if (ohc.recent_status.status === 1) {
        ohcColor = '#ddf5c2';
      }
      return {
        backgroundColor: ohcColor,
        color: textColor,
      };
    },
  },
};
</script>
